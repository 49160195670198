.App-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--header-background);
  padding: 20px;
  color: var(--text-color);
  height: 15vh;
  box-shadow: 0 4px 6px var(--box-shadow-color);
  
}

.line-1 { 
  flex: 0 0 auto;
  margin: 0 auto;
  border-right: 2px solid var(--text-color);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
  background-color: var(--background-color);
  color: var(--text-color);  
}

.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from { width: 0; }
  to { width: 13.5em; }
}

@keyframes blinkTextCursor {
  from { border-right-color: var(--text-color); }
  to { border-right-color: transparent; }
  
}

