/* Push content below the header */
.leaderboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 15vh); /* Adjust height to account for header */
  padding: 2rem;
  padding-top: calc(2rem + 15vh); /* Add space for header height */
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Ensure background color spans the entire page */
.leaderboard-container {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

/* Fix for table overflow */
table {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto; /* Center table horizontally */
  background-color: var(--card-background);
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--box-shadow-color);
}

/* Ensure rows fit within the table and don't cut off */
tbody {
  overflow-y: auto; /* Enable vertical scrolling for table body */
  max-height: calc(100vh - 15vh - 4rem); /* Adjust for header and padding */
}

/* Body background fix */
body {
  background-color: #2B2826; /* Matches the main background color */
  margin: 0; /* Remove default margins */
  font-family: 'Courier New', Courier, monospace;
}
