* {
  font-family: 'Courier New', Courier, monospace;
  box-sizing: border-box;
}

.signup-container {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.signup-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.signup-container h1 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  color: var(--text-color);
}

.form-group input {
  padding: 10px;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.button-49 {
  background-color: var(--button-bg);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button-49:hover {
  background-color: var(--button-hover);
}

