* {
  font-family: "Courier New", Courier, monospace;
  box-sizing: border-box;
}

.MainScreen {
  background-color: var(--background-color);
  min-height: 100vh;
}

.MainScreen-container {
  display: flex;
  flex-direction: row;
  height: 85vh;
  width: 100vw;
  background-color: var(--background-color);
}

.sidebar {
  width: 15vw;
  height: 85vh;
  background-color: var(--sidebar-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-shadow: 2px 0 5px var(--box-shadow-color);
}

.sidebar-button {
  color: var(--sidebar-text);
  background-color: var(--button-bg);
  border: 1px solid var(--card-border);
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.sidebar-button:hover {
  background-color: var(--button-hover);
}

.Mainscreen-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-top: 20px;
  overflow: hidden;
  gap: 20px;
  background-color: var(--main-content-bg);
}

.WPM,
.Accuracy {
  width: 10%;
  text-align: center;
  color: var(--text-color);
  background-color: var(--background-color);
}

.centered-div {
  background-color: var(--center-background);
  width: 80%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
  word-break: break-word;
  white-space: normal;
  font-size: 18px;
  line-height: 1.5;
  color: var(--generated-text-color);
  border: 1px solid var(--card-border);
  
}

.MainScreen-body {
  background-color: var(--main-content-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 85vw;
  color: var(--text-color);
  gap: 5px;
  
}

.duration-toggle {
  padding-bottom: 10px;
  color: var(--text-color);
  background-color: var(--background-color);
}

input[type="radio"] {
  display: none;
}

label[for="rad1"],
label[for="rad2"] {
  cursor: pointer;
  margin: 10px 10px;
  color: var(--text-color);
  background-color: var(--background-color);
}

label[for="rad1"]:before,
label[for="rad2"]:before {
  content: "";
  display: inline-block;
  height: 30px;
  width: 30px;
  background: var(--input-background);
  border: 1px solid var(--card-border);
  border-radius: 50%;
  z-index: 2;
  transition: box-shadow 0.4s ease, background 0.3s ease;
}

input:checked + label:before {
  box-shadow: inset 0px 3px 0 2px rgb(232, 52, 52);
  background: var(--text-color);
}

.hidden-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

select {
  background-color: var(--input-background);
  color: var(--text-color);
  border: 1px solid var(--card-border);
  padding: 5px;
  border-radius: 4px;
}

select option {
  background-color: var(--input-background);
  color: var(--text-color);
}

.difficulty-toggle {
  margin-bottom: 10px;
}

.difficulty-toggle select {
  padding: 8px;
  font-size: 14px;
}

/* Style for the text container */
.text-container {
  background-color: var(--input-background);
  border: 1px solid var(--card-border);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
  background-color: var(--background-color);
}

