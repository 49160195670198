:root {
    /* Light theme defaults */
    --background-color: #ffffff;
    --text-color: #000000;
    --sidebar-background: #ffffff;
    --sidebar-text: #333333;
    --card-background: #ffffff;
    --card-border: #e0e0e0;
    --header-background: #ffffff;
    --main-background: #ffffff;
    --centered-div-background: #ffffff;
    --center-background: #ffffff;
    --generated-text-color: #000000;
    --main-content-bg: #ffffff;
    --button-bg: #f0f0f0;
    --button-hover: #e0e0e0;
    --input-background: #ffffff;
    --box-shadow-color: rgba(0, 0, 0, 0.1);
    --table-border-color: #e0e0e0;
    --table-header-bg: #f5f5f5;
    --table-row-hover: #f8f8f8;
    --stat-text-color: #333333;
  }
  
  [data-theme='dark'] {
    --background-color: #2B2826;
    --text-color: #ffffff;
    --sidebar-background: #333333;
    --sidebar-text: #ffffff;
    --card-background: #2B2826;
    --card-border: #4a4a4a;
    --header-background: #2B2826;
    --main-background: #2B2826;
    --centered-div-background: #2B2826;
    --center-background: #2B2826;
    --generated-text-color: #ffffff;
    --main-content-bg: #2B2826;
    --button-bg: #444444;
    --button-hover: #555555;
    --input-background: #333333;
    --box-shadow-color: rgba(0, 0, 0, 0.5);
    --table-border-color: #4a4a4a;
    --table-header-bg: #333333;
    --table-row-hover: #3a3a3a;
    --stat-text-color: #ffffff;
  }
  
  /* Add transition for smooth theme switching */
  * {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  